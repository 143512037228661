import { BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../api";
import {
  barChartSlotProps,
  ByChartData,
  carrierColors,
  chartColors,
  ChartDatasets,
  chartMargins,
  ChartType,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

interface CarrierCostDataItem {
  month: string;
  [carrier: string]: string | number;
}

export default function CostByCarrierBarChart({
  data,
  startDate,
  endDate,
  dataKeys,
  selectedDataKeys,
  chartType,
  fieldNames,
  minusFieldNames,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  dataKeys: string[];
  selectedDataKeys: string[];
  chartType: ChartType;
  fieldNames: string[];
  minusFieldNames: string[];
  dataSet: ChartDatasets;
}) {
  const processedData: CarrierCostDataItem[] = useMemo(() => {
    const filteredData = data.filter((item) =>
      moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
    );

    const monthlyCarrierTotal = filteredData.map((item) => {
      const month = moment(item.invoice_date).format("MMM YYYY");

      const cost = fieldNames.reduce((acc, fieldName) => {
        return acc + Number(item[fieldName as keyof UsageAndCostItem]);
      }, 0);

      const minusCost = minusFieldNames.reduce((acc, fieldName) => {
        return acc + Number(item[fieldName as keyof UsageAndCostItem]);
      }, 0);

      return {
        month,
        carrier: item.carrier_name,
        cost: cost - minusCost,
      };
    });

    const monthlyCarrierTotalMap: {
      [month: string]: { [carrier: string]: number };
    } = {};

    monthlyCarrierTotal.forEach((item) => {
      if (!monthlyCarrierTotalMap[item.month]) {
        monthlyCarrierTotalMap[item.month] = {};
      }

      if (!monthlyCarrierTotalMap[item.month][item.carrier]) {
        monthlyCarrierTotalMap[item.month][item.carrier] = 0;
      }

      monthlyCarrierTotalMap[item.month][item.carrier] += item.cost;
    });

    const aggregatedData: CarrierCostDataItem[] = [];

    Object.entries(monthlyCarrierTotalMap).forEach(([month, carrierData]) => {
      aggregatedData.push({
        month,
        ...carrierData,
      });
    });

    return aggregatedData.sort((a, b) =>
      moment(a.month, "MMM YYYY").diff(moment(b.month, "MMM YYYY"))
    );
  }, [data, startDate, endDate, fieldNames, minusFieldNames]);

  const displayedData: CarrierCostDataItem[] = useMemo(() => {
    return processedData.map((item) => {
      const displayedData: CarrierCostDataItem = {
        month: item.month,
      };

      selectedDataKeys.forEach((key) => {
        displayedData[key] = item[key] || 0;
      });

      return displayedData;
    });
  }, [processedData, selectedDataKeys]);

  // format the data for the chart
  // turn months from "2023-09-01" to "Sep 2023"
  const formattedData: {
    month: string;
    [key: string]: string | number;
  }[] = useMemo(() => {
    return displayedData.map((item) => {
      return {
        ...item,
        month: moment(item.month, "MMM YYYY").format("MMM YY"),
      };
    });
  }, [displayedData]);

  const handleExportData = useCallback(() => {
    if (formattedData.length === 0) return;

    // Get all keys except 'month'
    const keys = Object.keys(formattedData[0]).filter((key) => key !== "month");

    // Create CSV header
    const header = ["month", ...keys].join(",");

    // Create CSV rows
    const rows = formattedData.map((item) => {
      return [
        item.month,
        ...keys.map((key) => currencyFormatter(item[key] || 0, false)),
      ].join(",");
    });

    // Combine header and rows
    const csvContent = [header, ...rows].join("\n");

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "total-cost-data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [formattedData]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet,
      chartType,
      byChartData: ByChartData.CARRIER,
      startDate,
      endDate,
    });
  }, [dataSet, chartType, startDate, endDate]);

  // listen for "export-as-image" event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, handleCaptureChart);

    return () => {
      window.removeEventListener(ChartEvents.ExportImage, handleCaptureChart);
    };
  }, [handleCaptureChart]);

  // listen for "export-data" event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);

    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  const chartSeries: MakeOptional<BarSeriesType, "type">[] = dataKeys.map(
    (key) => ({
      dataKey: key,
      label: key,
      stack: chartType === ChartType.BAR ? "carrier" : undefined,
      valueFormatter: (value) => currencyFormatter(value),
      color:
        carrierColors[key] ||
        chartColors[dataKeys.indexOf(key) % chartColors.length],
    })
  );

  return (
    <BarChart
      data-testid="cost-by-carrier-bar-chart"
      dataset={formattedData}
      series={chartSeries}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      slotProps={barChartSlotProps}
      margin={chartMargins}
    />
  );
}
