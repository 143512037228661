import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { ByChartData, ByChartDataMap } from "../constants";
import ChartButton from "./ChartButton";
import { getChartDataKeyLabel } from "./utils";

export default function ChartShell({
  children,
  dataKeyOptions,
  selectedDataKeys,
  selectedByChartData,
  onSelectedDataKeysChange,
  byChartDataOptions,
  onByChartDataChange,
  dataSourceOptions = [],
  selectedDataSources = [],
  onDataSourcesChange,
  isMenuCollapsed,
  onMenuCollapsedChange,
}: {
  children: React.ReactNode;
  dataKeyOptions: string[];
  selectedDataKeys: string[];
  selectedByChartData: ByChartData;
  onSelectedDataKeysChange?: (selectedDataKeys: string[]) => void;
  byChartDataOptions: ByChartData[];
  onByChartDataChange?: (byChartData: ByChartData) => void;
  dataSourceOptions?: string[];
  selectedDataSources?: string[];
  onDataSourcesChange?: (selectedSources: string[]) => void;
  isMenuCollapsed: boolean;
  onMenuCollapsedChange: (collapsed: boolean) => void;
}) {
  const handleByChartDataChange = (byChartData: ByChartData) => {
    onByChartDataChange && onByChartDataChange(byChartData);
  };

  const handleChartDataKeyChange = (key: string) => {
    const updatedSelectedDataKeys = [...selectedDataKeys];
    if (updatedSelectedDataKeys.includes(key)) {
      updatedSelectedDataKeys.splice(updatedSelectedDataKeys.indexOf(key), 1);
    } else {
      updatedSelectedDataKeys.push(key);
    }

    // call the callback with the updated keys
    onSelectedDataKeysChange &&
      onSelectedDataKeysChange(updatedSelectedDataKeys);
  };

  const handleDataSourceChange = (source: string) => {
    const updatedSources = [...selectedDataSources];
    if (updatedSources.includes(source)) {
      updatedSources.splice(updatedSources.indexOf(source), 1);
    } else {
      updatedSources.push(source);
    }

    onDataSourcesChange?.(updatedSources);
  };

  const handleMenuCollapse = () => {
    onMenuCollapsedChange(!isMenuCollapsed);
  };

  return (
    <div
      className="flex flex-1 flex-row gap-2"
      style={{
        maxHeight: "calc(100vh - 300px)",
      }}
    >
      <div
        className={`flex flex-col bg-primary-800 gap-8 transition-all duration-300 ease-in-out ${
          isMenuCollapsed ? "w-12 px-1" : "w-60 px-4"
        } pt-4`}
      >
        <div className="flex flex-row justify-end">
          <IconButton
            onClick={handleMenuCollapse}
            sx={{ color: "white" }}
            aria-label={isMenuCollapsed ? "Expand menu" : "Collapse menu"}
            aria-expanded={!isMenuCollapsed}
            aria-controls="chart-menu-content"
            data-testid="chart-menu-toggle"
          >
            {isMenuCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>

        <div
          id="chart-menu-content"
          className={`transition-opacity duration-300 overflow-y-auto pb-8 ${
            isMenuCollapsed ? "opacity-0" : "opacity-100"
          }`}
        >
          {!isMenuCollapsed && (
            <>
              <div className="flex flex-col gap-2">
                <div className="text-white opacity-80 text-sm font-medium">
                  Chart Data
                </div>

                <div className="flex flex-col gap-2">
                  {byChartDataOptions.map((byChartDataOption) => (
                    <ChartButton
                      key={byChartDataOption}
                      label={`by ${ByChartDataMap[byChartDataOption]}`}
                      active={selectedByChartData === byChartDataOption}
                      onClick={() => handleByChartDataChange(byChartDataOption)}
                    />
                  ))}
                </div>
              </div>

              {dataKeyOptions.length > 0 && (
                <div className="flex flex-col gap-2 mt-6">
                  <div className="text-white opacity-80 text-sm font-medium">
                    Data Keys
                  </div>
                  <div className="flex flex-col gap-1">
                    {dataKeyOptions.sort().map((chartDataKey) => (
                      <FormControlLabel
                        key={chartDataKey}
                        control={
                          <Checkbox
                            sx={{
                              // update checkbox color to be white with a blue border and checkmark
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                              },
                              "&.MuiCheckbox-root": {
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              },
                            }}
                            checked={selectedDataKeys.includes(chartDataKey)}
                          />
                        }
                        label={getChartDataKeyLabel(chartDataKey)}
                        sx={{ color: "white" }}
                        onChange={() => {
                          handleChartDataKeyChange(chartDataKey);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}

              {dataSourceOptions.length > 0 && (
                <div className="flex flex-col gap-2 mt-6">
                  <div className="text-white opacity-80 text-sm font-medium">
                    Data Sources
                  </div>
                  <div className="flex flex-col gap-1">
                    {dataSourceOptions.sort().map((source) => (
                      <FormControlLabel
                        key={source}
                        control={
                          <Checkbox
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                              },
                              "&.MuiCheckbox-root": {
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              },
                            }}
                            checked={selectedDataSources.includes(source)}
                          />
                        }
                        value={source}
                        label={source}
                        sx={{ color: "white" }}
                        onChange={() => handleDataSourceChange(source)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div id="chart-container" className="flex flex-1">
        {children}
      </div>
    </div>
  );
}
