export const chartColors: string[] = [
  "#5C88C5",
  "#F5796D",
  "#64C195",
  "#FCC777",
  "#BD7CB4",
  "#F58D74",
  "#ADD68A",
  "#EBE87B",
  "#6578D9",
  "#F5A872",
  "#54C5C3",
  "#7E6AAF",
];
