import moment from "moment";
import { UsageAndCostItem } from "../../api";

export interface HistoricalCostDataItem {
  month: string;
  total_charges: number;
  mrc: number;
  equipment_total: number;
  data_overage: number;
  international_total: number;
  total_other: number;
  total_tax: number;
}

export function processHistoricalData(
  data: UsageAndCostItem[],
  startDate: string,
  endDate: string
) {
  const filteredData = data.filter((item) =>
    moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
  );

  const monthlyTotals: { [key: string]: HistoricalCostDataItem } = {};

  filteredData.forEach((item) => {
    const month = moment(item.invoice_date).format("MMM YY");

    if (!monthlyTotals[month]) {
      monthlyTotals[month] = {
        month,
        total_charges: 0,
        mrc: 0,
        equipment_total: 0,
        data_overage: 0,
        international_total: 0,
        total_other: 0,
        total_tax: 0,
      };
    }

    monthlyTotals[month].total_charges += Number(item.total_charges) || 0;
    monthlyTotals[month].mrc += Number(item.mrc) || 0;
    monthlyTotals[month].equipment_total += Number(item.equipment_total) || 0;
    monthlyTotals[month].data_overage +=
      Number(item.kb_charges) - Number(item.intl_data_roam_charges) || 0;
    monthlyTotals[month].international_total +=
      Number(item.international_total) || 0;
    monthlyTotals[month].total_other += Number(item.total_other) || 0;
    monthlyTotals[month].total_tax += Number(item.total_tax) || 0;
  });

  return Object.values(monthlyTotals).sort((a, b) =>
    moment(a.month, "MMM YY").diff(moment(b.month, "MMM YY"))
  );
}
