import { LineChart, LineChartSlotProps } from "@mui/x-charts";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../api";
import {
  barChartSlotProps,
  ByChartData,
  carrierColors,
  chartColors,
  ChartDatasets,
  ChartType,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

const CostOverTimeLineChart = ({
  startDate,
  endDate,
  usageAndCostData,
  byChartData,
  selectedDataFilters,
  fieldNames,
  minusFieldNames,
  dataSet,
}: {
  usageAndCostData: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  byChartData: ByChartData;
  selectedDataFilters: string[];
  fieldNames: string[];
  minusFieldNames: string[];
  dataSet: ChartDatasets;
}) => {
  const lineChartData = useMemo(() => {
    const startDateMoment = moment(startDate, "YYYY-MM-DD");
    const endDateMoment = moment(endDate, "YYYY-MM-DD");

    // Create a map to store monthly totals for each dataset
    const monthlyTotals: { [key: string]: { [month: string]: number } } = {};

    // Initialize the data structure for selected filters
    selectedDataFilters.forEach((filter) => {
      monthlyTotals[filter] = {};
    });

    // Process the data
    usageAndCostData.forEach((item) => {
      const itemDate = moment(item.invoice_date);
      const isInTimeRange = itemDate.isBetween(
        startDateMoment,
        endDateMoment,
        "day",
        "[]"
      );
      const dataKey =
        byChartData === ByChartData.CARRIER
          ? item.carrier_name
          : item.formatted_device_type;

      if (isInTimeRange && selectedDataFilters.includes(dataKey)) {
        const monthKey = itemDate.format("MMM YY");

        // Calculate cost for this item
        const cost = fieldNames.reduce((acc, fieldName) => {
          return acc + Number(item[fieldName as keyof UsageAndCostItem]);
        }, 0);

        const minusCost = minusFieldNames.reduce((acc, fieldName) => {
          return acc + Number(item[fieldName as keyof UsageAndCostItem]);
        }, 0);

        // Initialize or add to the monthly total
        if (!monthlyTotals[dataKey][monthKey]) {
          monthlyTotals[dataKey][monthKey] = 0;
        }
        monthlyTotals[dataKey][monthKey] += cost - minusCost;
      }
    });

    // Get all unique months
    const allMonths = Array.from(
      new Set(
        usageAndCostData
          .map((item) => moment(item.invoice_date).format("MMM YY"))
          .filter((month) => {
            const monthDate = moment(month, "MMM YY");
            return monthDate.isBetween(
              startDateMoment,
              endDateMoment,
              "month",
              "[]"
            );
          })
      )
    ).sort((a, b) => moment(a, "MMM YY").diff(moment(b, "MMM YY")));

    // Format data for the chart
    return allMonths.map((month) => {
      const dataPoint: { [key: string]: string | number } = { month };
      selectedDataFilters.forEach((filter) => {
        dataPoint[filter] = monthlyTotals[filter][month] || 0;
      });
      return dataPoint;
    });
  }, [
    byChartData,
    startDate,
    endDate,
    fieldNames,
    minusFieldNames,
    selectedDataFilters,
    usageAndCostData,
  ]);

  const handleExportData = useCallback(async () => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const csvData = [
      ["Month", ...selectedDataFilters],
      ...lineChartData.map((item) => [
        item.month,
        ...selectedDataFilters.map((filter) =>
          formatter.format(item[filter] as number)
        ),
      ]),
    ];

    const csvContent = csvData
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "cost-over-time.csv";
    a.click();
    URL.revokeObjectURL(url);
  }, [lineChartData, selectedDataFilters]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);
    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, () =>
      captureChart({
        dataSet,
        chartType: ChartType.LINE,
        byChartData,
        startDate,
        endDate,
      })
    );

    return () => {
      window.removeEventListener(ChartEvents.ExportImage, () =>
        captureChart({
          dataSet,
          chartType: ChartType.LINE,
          byChartData,
          startDate,
          endDate,
        })
      );
    };
  }, [byChartData, dataSet, startDate, endDate]);

  return (
    <LineChart
      data-testid="cost-over-time-line-chart"
      dataset={lineChartData}
      series={selectedDataFilters.map((filter) => ({
        dataKey: filter,
        label: filter,
        valueFormatter: (value) => currencyFormatter(value),
        color:
          carrierColors[filter] ||
          chartColors[selectedDataFilters.indexOf(filter) % chartColors.length],
      }))}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      height={window.innerHeight - 300}
      slotProps={barChartSlotProps as LineChartSlotProps}
      margin={{ left: 80, right: 20, top: 20, bottom: 20 }}
    />
  );
};

export default CostOverTimeLineChart;
