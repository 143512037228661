import { BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../api";
import {
  ByChartData,
  chartColors,
  ChartDatasets,
  chartMargins,
  ChartType,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

interface DeviceCostDataItem {
  month: string;
  [deviceType: string]: string | number;
}

export default function CostByDeviceTypeBarChart({
  data,
  startDate,
  endDate,
  selectedDataKeys,
  dataKeys,
  chartType,
  fieldNames,
  minusFieldNames,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  selectedDataKeys: string[];
  dataKeys: string[];
  chartType: ChartType;
  fieldNames: string[];
  minusFieldNames: string[];
  dataSet: ChartDatasets;
}) {
  const processedData: DeviceCostDataItem[] = useMemo(() => {
    const filteredData = data.filter((item) =>
      moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
    );

    const monthlyDeviceTotalMap: {
      [month: string]: { [deviceType: string]: number };
    } = {};

    // iterate over the data and aggregate the total cost by month and device type
    for (const item of filteredData) {
      const month = moment(item.invoice_date).format("MMM YYYY");

      if (!monthlyDeviceTotalMap[month]) {
        monthlyDeviceTotalMap[month] = {};
      }

      if (!monthlyDeviceTotalMap[month][item.formatted_device_type]) {
        monthlyDeviceTotalMap[month][item.formatted_device_type] = 0;
      }

      const cost = fieldNames.reduce((acc, fieldName) => {
        return acc + Number(item[fieldName as keyof UsageAndCostItem]);
      }, 0);

      const minusCost = minusFieldNames.reduce((acc, fieldName) => {
        return acc + Number(item[fieldName as keyof UsageAndCostItem]);
      }, 0);

      monthlyDeviceTotalMap[month][item.formatted_device_type] +=
        cost - minusCost;
    }

    const aggregatedData: DeviceCostDataItem[] = [];

    const currentDate = moment(startDate);
    const endMoment = moment(endDate);

    while (currentDate.isSameOrBefore(endMoment)) {
      const month = currentDate.format("MMM YYYY");
      const deviceTypeData = monthlyDeviceTotalMap[month] || {};

      aggregatedData.push({
        month,
        ...deviceTypeData,
      });

      currentDate.add(1, "month");
    }

    return aggregatedData;
  }, [data, startDate, endDate, fieldNames, minusFieldNames]);

  const displayedData: DeviceCostDataItem[] = useMemo(() => {
    return processedData.map((item) => {
      const displayedItem: DeviceCostDataItem = { month: item.month };

      selectedDataKeys.forEach((key) => {
        displayedItem[key] = item[key] || 0;
      });

      return displayedItem;
    });
  }, [processedData, selectedDataKeys]);

  // format the data for the chart
  // turn months from "2023-09-01" to "Sep 2023"
  const formattedData: {
    month: string;
    [key: string]: string | number;
  }[] = useMemo(() => {
    return displayedData.map((item) => {
      return {
        ...item,
        month: moment(item.month, "MMM YYYY").format("MMM YY"),
      };
    });
  }, [displayedData]);

  const handleExportData = useCallback(() => {
    if (formattedData.length === 0) return;

    // Get all keys except 'month'
    const keys = Object.keys(formattedData[0]).filter((key) => key !== "month");

    // Create CSV header
    const header = ["month", ...keys].join(",");

    // Create CSV rows
    const rows = formattedData.map((item) => {
      return [
        item.month,
        ...keys.map((key) => currencyFormatter(item[key] || 0, false)),
      ].join(",");
    });

    // Combine header and rows
    const csvContent = [header, ...rows].join("\n");

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "total-cost-data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [formattedData]);

  // listen for "export-as-image" event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, () =>
      captureChart({
        dataSet,
        chartType,
        byChartData: ByChartData.DEVICE,
        startDate,
        endDate,
      })
    );

    return () => {
      window.removeEventListener(ChartEvents.ExportImage, () =>
        captureChart({
          dataSet,
          chartType,
          byChartData: ByChartData.DEVICE,
          startDate,
          endDate,
        })
      );
    };
  }, [chartType, dataSet, startDate, endDate]);

  // listen for "export-data" event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);

    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  const chartSeries: MakeOptional<BarSeriesType, "type">[] = dataKeys.map(
    (key, index) => ({
      dataKey: key,
      label: key,
      stack: chartType === ChartType.BAR ? "deviceType" : undefined,
      valueFormatter: (value) => currencyFormatter(value),
      color: chartColors[index % chartColors.length],
    })
  );

  return (
    <BarChart
      data-testid="cost-by-device-bar-chart"
      dataset={formattedData}
      series={chartSeries}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      margin={chartMargins}
    />
  );
}
