import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";
import { SavingsAnalysisItem, SavingsAnalysisSchema } from "./types";

export const getSavingsAnalysis = async (): Promise<SavingsAnalysisItem[]> => {
  const { data } = await axios.get<SavingsAnalysisItem[]>(
    buildApiUrl(`/api/report/SavingsAnalysis`)
  );

  return data;
};

export const getSavingsAnalysisSchema =
  async (): Promise<SavingsAnalysisSchema> => {
    const { data } = await axios.get<SavingsAnalysisSchema>(
      buildApiUrl(`/api/report_schema/SavingsAnalysis`)
    );

    return data;
  };
