import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { UsageAndCostItem } from "../api";
import ChartShell from "../ChartShell";
import { getChartDataKeys } from "../ChartShell/utils";
import { ByChartData, ChartDatasets, ChartType } from "../constants";
import AverageCostPerDeviceChart from "./AverageCostPerDeviceChart";
import CostByCarrierBarChart from "./CostByCarrierBarChart";
import CostByCategoryBarChart from "./CostByCategoryBarChart";
import CostByDeviceTypeBarChart from "./CostByDeviceBarChart";
import CostOverTimeLineChart from "./CostOverTimeLineChart";
import HistoricalCostsChart from "./HistoricalCostsChart";

export default function DataAndUsageCostChart({
  startDate,
  endDate,
  selectedChartType,
  usageAndCostData,
  isLoading,
  fieldNames,
  minusFieldNames,
  byChartDataOptions,
  dataSet,
  dataSourceOptions,
  isMenuCollapsed,
  onMenuCollapsedChange,
}: {
  startDate: string;
  endDate: string;
  selectedChartType: ChartType;
  usageAndCostData: UsageAndCostItem[];
  isLoading: boolean;
  fieldNames: string[];
  minusFieldNames: string[];
  byChartDataOptions: ByChartData[];
  dataSet: ChartDatasets;
  dataSourceOptions: string[];
  isMenuCollapsed: boolean;
  onMenuCollapsedChange: (collapsed: boolean) => void;
}) {
  const [selectedByChartData, setSelectedByChartData] = useState<ByChartData>(
    byChartDataOptions[0]
  );
  const [chartDataKeys, setChartDataKeys] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedDataSources, setSelectedDataSources] =
    useState<string[]>(dataSourceOptions);

  const selectedDataKeys = Object.keys(chartDataKeys).filter(
    (key) => chartDataKeys[key]
  );

  const handleSelectedDataKeysChange = (selectedDataKeys: string[]) => {
    setChartDataKeys(
      Object.fromEntries(
        Object.keys(chartDataKeys).map((key) => [
          key,
          selectedDataKeys.includes(key),
        ])
      )
    );
  };

  const handleDataSourcesChange = (selectedSources: string[]) => {
    setSelectedDataSources(selectedSources);
  };

  // set chart data keys for usageAndCostData
  useEffect(() => {
    const chartDataKeys = getChartDataKeys(
      usageAndCostData,
      selectedByChartData
    );
    setChartDataKeys(
      Object.fromEntries(chartDataKeys.map((key) => [key, true]))
    );
  }, [selectedByChartData, usageAndCostData]);

  // auto-select the byChartData based on the available byChartDataOptions
  useEffect(() => {
    if (byChartDataOptions.length > 0) {
      setSelectedByChartData(byChartDataOptions[0]);
    }
  }, [byChartDataOptions]);

  // set selectedDataSources to all carrier names as default when the "Dataset" changes
  useEffect(() => {
    // the selecteDataSources should default to all the dataSourceOptions whenever the dataSet changes
    setSelectedDataSources(dataSourceOptions);
  }, [dataSet, dataSourceOptions]);

  const renderChart = () => {
    if (dataSet === ChartDatasets.HistoricalCosts) {
      return (
        <HistoricalCostsChart
          data={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          selectedDataKeys={selectedDataKeys}
          chartType={selectedChartType}
          dataSet={dataSet}
        />
      );
    }

    if (dataSet === ChartDatasets.AverageCostPerDevice) {
      return (
        <AverageCostPerDeviceChart
          data={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          selectedDataKeys={selectedDataKeys}
          chartType={selectedChartType}
          fieldNames={fieldNames}
          dataSet={dataSet}
        />
      );
    }

    if (
      [ChartType.BAR, ChartType.CLUSTERED_BAR].includes(selectedChartType) &&
      selectedByChartData === ByChartData.CARRIER
    ) {
      return (
        <CostByCarrierBarChart
          data={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          dataKeys={Object.keys(chartDataKeys)}
          selectedDataKeys={selectedDataKeys}
          chartType={selectedChartType}
          fieldNames={fieldNames}
          minusFieldNames={minusFieldNames}
          dataSet={dataSet}
        />
      );
    }

    if (
      [ChartType.BAR, ChartType.CLUSTERED_BAR].includes(selectedChartType) &&
      selectedByChartData === ByChartData.DEVICE
    ) {
      return (
        <CostByDeviceTypeBarChart
          data={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          dataKeys={Object.keys(chartDataKeys)}
          selectedDataKeys={selectedDataKeys}
          chartType={selectedChartType}
          fieldNames={fieldNames}
          minusFieldNames={minusFieldNames}
          dataSet={dataSet}
        />
      );
    }

    if (
      [ChartType.BAR, ChartType.CLUSTERED_BAR].includes(selectedChartType) &&
      selectedByChartData === ByChartData.COST_CATEGORIES
    ) {
      return (
        <CostByCategoryBarChart
          data={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          selectedDataKeys={selectedDataKeys}
          chartType={selectedChartType}
          fieldNames={fieldNames}
          dataSet={dataSet}
          selectedDataSources={selectedDataSources}
        />
      );
    }

    if (selectedChartType === "line") {
      return (
        <CostOverTimeLineChart
          usageAndCostData={usageAndCostData}
          startDate={startDate}
          endDate={endDate}
          byChartData={selectedByChartData}
          selectedDataFilters={selectedDataKeys}
          fieldNames={fieldNames}
          minusFieldNames={minusFieldNames}
          dataSet={dataSet}
        />
      );
    }
  };

  return (
    <ChartShell
      dataKeyOptions={Object.keys(chartDataKeys)}
      selectedDataKeys={selectedDataKeys}
      onSelectedDataKeysChange={handleSelectedDataKeysChange}
      selectedByChartData={selectedByChartData}
      byChartDataOptions={byChartDataOptions}
      onByChartDataChange={setSelectedByChartData}
      dataSourceOptions={dataSourceOptions}
      selectedDataSources={selectedDataSources}
      onDataSourcesChange={handleDataSourcesChange}
      isMenuCollapsed={isMenuCollapsed}
      onMenuCollapsedChange={onMenuCollapsedChange}
    >
      {isLoading ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <CircularProgress size={60} />
        </div>
      ) : (
        renderChart()
      )}
    </ChartShell>
  );
}
