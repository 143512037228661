import {
  ByChartData,
  CostCategoryMap,
  DataKeyToNameMap,
  HistoricalCostsCategoriesMap,
} from "../constants";

interface DataItem {
  carrier_name?: string;
  formatted_device_type?: string;
  company_cost_ctr?: string;
}

export function getChartDataKeys(
  data: DataItem[],
  byChartData: ByChartData
): string[] {
  switch (byChartData) {
    case ByChartData.CARRIER:
      // get all unique carriers by name
      return Array.from(
        new Set(data.map((item) => item.carrier_name || ""))
      ).sort();

    case ByChartData.DEVICE:
      // get all unique device types by name
      return Array.from(
        new Set(data.map((item) => item.formatted_device_type || ""))
      ).sort();

    case ByChartData.HISTORICAL_COSTS_CATEGORIES:
      return Object.keys(HistoricalCostsCategoriesMap).sort();

    case ByChartData.COST_CATEGORIES:
      // get all unique cost categories by name
      return Object.keys(CostCategoryMap).sort();

    case ByChartData.COST_BY_COST_CENTER:
      // get all unique cost centers by name
      return Array.from(
        new Set(data.map((item) => item.company_cost_ctr || ""))
      )
        .filter((item) => item.length > 0)
        .sort();

    default:
      return [];
  }
}

export function getChartDataKeyLabel(key: string) {
  if (DataKeyToNameMap[key]) {
    return DataKeyToNameMap[key];
  }

  return key;
}
