import { BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../api";
import {
  barChartSlotProps,
  ByChartData,
  chartColors,
  ChartDatasets,
  chartMargins,
  ChartType,
  CostCategoryMap,
  MAX_MONTHS_FOR_COST_BY_CATEGORY,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

interface CategoryCostDataItem {
  category: string;
  [month: string]: string | number;
}

export default function CostByCategoryBarChart({
  data,
  startDate,
  endDate,
  selectedDataKeys,
  selectedDataSources = [],
  chartType,
  fieldNames,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  selectedDataKeys: string[];
  selectedDataSources?: string[];
  chartType: ChartType;
  fieldNames: string[];
  dataSet: ChartDatasets;
}) {
  const processedData: CategoryCostDataItem[] = useMemo(() => {
    const filteredData = data
      .filter((item) =>
        moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
      )
      .filter((item) => {
        const endMoment = moment(endDate);
        const itemDate = moment(item.invoice_date);
        const monthsDiff = endMoment.diff(itemDate, "months");
        return monthsDiff < MAX_MONTHS_FOR_COST_BY_CATEGORY;
      })
      .filter(
        (item) =>
          selectedDataSources.length > 0 &&
          selectedDataSources.includes(item.carrier_name || "")
      );

    const categoryMonthTotalMap: {
      [category: string]: { [month: string]: number };
    } = {};

    for (const fieldName of fieldNames) {
      categoryMonthTotalMap[fieldName] = {};
    }

    for (const item of filteredData) {
      const month = moment(item.invoice_date).format("MMM YY");

      // Initialize the month for each category if it doesn't exist
      fieldNames.forEach((fieldName) => {
        if (!categoryMonthTotalMap[fieldName][month]) {
          categoryMonthTotalMap[fieldName][month] = 0;
        }
      });

      // Calculate MRC (total_charges minus equipment_total)
      if (categoryMonthTotalMap["mrc"]) {
        categoryMonthTotalMap["mrc"][month] +=
          Number(item.total_charges) - Number(item.equipment_total);
      }

      // Equipment total
      if (categoryMonthTotalMap["equipment_total"]) {
        categoryMonthTotalMap["equipment_total"][month] += Number(
          item.equipment_total
        );
      }

      // Data overage (kb_charges minus intl_data_roam_charges)
      if (categoryMonthTotalMap["data_overage"]) {
        categoryMonthTotalMap["data_overage"][month] +=
          Number(item.kb_charges) - Number(item.intl_data_roam_charges);
      }

      // International total
      if (categoryMonthTotalMap["international_total"]) {
        categoryMonthTotalMap["international_total"][month] += Number(
          item.international_total
        );
      }

      // Total other
      if (categoryMonthTotalMap["total_other"]) {
        categoryMonthTotalMap["total_other"][month] += Number(
          item.total_other || 0
        );
      }

      // Total tax
      if (categoryMonthTotalMap["total_tax"]) {
        categoryMonthTotalMap["total_tax"][month] += Number(
          item.total_tax || 0
        );
      }
    }

    return Object.entries(categoryMonthTotalMap)
      .filter(([category]) => selectedDataKeys.includes(category))
      .map(([category, monthValues]) => ({
        category: CostCategoryMap[category],
        ...monthValues,
      }));
  }, [
    data,
    startDate,
    endDate,
    fieldNames,
    selectedDataKeys,
    selectedDataSources,
  ]);

  const months = useMemo(() => {
    const allMonths = new Set<string>();
    processedData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== "category") allMonths.add(key);
      });
    });
    return Array.from(allMonths).sort((a, b) =>
      moment(a, "MMM YY").diff(moment(b, "MMM YY"))
    );
  }, [processedData]);

  const barChartSeries: MakeOptional<BarSeriesType, "type">[] = useMemo(() => {
    return months.map((month, index) => ({
      dataKey: month,
      label: month,
      stack: chartType === ChartType.BAR ? "month" : undefined,
      valueFormatter: (value) => currencyFormatter(value),
      color: chartColors[index % chartColors.length],
    }));
  }, [months, chartType]);

  const handleExportData = useCallback(() => {
    if (processedData.length === 0) return;

    const header = ["category", ...months].join(",");
    const rows = processedData.map((item) => {
      return [
        item.category,
        ...months.map((month) => currencyFormatter(item[month] || 0, false)),
      ].join(",");
    });

    const csv = [header, ...rows].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "cost-by-category.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [processedData, months]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet,
      chartType,
      byChartData: ByChartData.COST_CATEGORIES,
      startDate,
      endDate,
    });
  }, [dataSet, chartType, startDate, endDate]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, handleCaptureChart);
    return () => {
      window.removeEventListener(ChartEvents.ExportImage, handleCaptureChart);
    };
  }, [handleCaptureChart]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);
    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  return (
    <BarChart
      data-testid="cost-by-category-bar-chart"
      dataset={processedData}
      series={barChartSeries}
      xAxis={[{ scaleType: "band", dataKey: "category" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      slotProps={barChartSlotProps}
      margin={chartMargins}
    />
  );
}
