import {
  Button,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaChartBar, FaChartLine } from "react-icons/fa";
import { IoBarChart } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getStatementMonths,
  getUsageAndCostData,
  UsageAndCostItem,
} from "./api";
import { getChartDataKeys } from "./ChartShell/utils";
import {
  ByChartData,
  ChartDatasets,
  ChartType,
  MAX_MONTHS_FOR_COST_BY_CATEGORY,
} from "./constants";
import CostByCostCenterBarChart from "./CostByCostCenterBarChart";
import DataAndUsageCostsChart from "./DataAndUsageCostsChart";
import { ChartEvents } from "./events";

export default function ChartsDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [monthsToChart, setMonthsToChart] = useState(
    parseInt(queryParams.get("months") || "12")
  );
  const [endDate, setEndDate] = useState(queryParams.get("endDate") || "");
  const [statementMonths, setStatementMonths] = useState<string[]>([]);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    (queryParams.get("chartType") as ChartType) || ChartType.BAR
  );
  const [selectedDataset, setSelectedDataset] = useState<ChartDatasets>(
    (queryParams.get("dataset") as ChartDatasets) || ChartDatasets.TotalCost
  );
  const [usageAndCostData, setUsageAndCostData] = useState<UsageAndCostItem[]>(
    []
  );
  const [usageAndCostDataMonthMap, setUsageAndCostDataMonthMap] = useState<{
    [monthCombo: string]: UsageAndCostItem[];
  }>({});
  const [loadingData, setLoadingData] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const updateQueryParams = useCallback(() => {
    const newParams = new URLSearchParams();
    newParams.set("months", monthsToChart.toString());
    if (endDate) newParams.set("endDate", endDate);
    newParams.set("dataset", selectedDataset);
    newParams.set("chartType", selectedChartType);
    navigate({ search: newParams.toString() }, { replace: true });
  }, [monthsToChart, endDate, selectedDataset, selectedChartType, navigate]);

  // Determine the last available statement month
  const lastStatementMonth = useMemo(() => {
    if (statementMonths.length === 0) return "";
    return moment
      .max(statementMonths.map((month) => moment(month, "YYYY-MM")))
      .format("YYYY-MM");
  }, [statementMonths]);

  // Determine the first available statement month
  const firstStatementMonth = useMemo(() => {
    if (statementMonths.length === 0) return "";
    return moment
      .min(statementMonths.map((month) => moment(month, "YYYY-MM")))
      .format("YYYY-MM");
  }, [statementMonths]);

  // Calculate available months range based on end date
  const monthsRange = useMemo(() => {
    if (!endDate || !firstStatementMonth || !lastStatementMonth)
      return { min: 1, max: 1 };

    const endMoment = moment(endDate, "YYYY-MM");
    const firstMoment = moment(firstStatementMonth, "YYYY-MM");

    return {
      min: 1,
      max: endMoment.diff(firstMoment, "months") + 1,
    };
  }, [endDate, firstStatementMonth, lastStatementMonth]);

  const handleGetUsageAndCostData = useCallback(async () => {
    if (!endDate || !monthsToChart) return;

    const formattedEndDate = moment(endDate, "YYYY-MM").format("YYYY-MM-01");
    const cacheKey = `${monthsToChart}_${endDate}`;

    if (usageAndCostDataMonthMap[cacheKey]) {
      setUsageAndCostData(usageAndCostDataMonthMap[cacheKey]);
      return;
    }

    setUsageAndCostData([]);

    try {
      setLoadingData(true);

      const resp = await getUsageAndCostData(formattedEndDate, monthsToChart);

      setUsageAndCostData(resp.usage_and_cost);

      setUsageAndCostDataMonthMap((prevMap) => ({
        ...prevMap,
        [cacheKey]: resp.usage_and_cost,
      }));
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingData(false);
    }
  }, [endDate, monthsToChart, usageAndCostDataMonthMap]);

  const allowedChartTypes = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.CostByCategory:
        return [ChartType.CLUSTERED_BAR];

      case ChartDatasets.CostByCostCenter:
        return [ChartType.BAR, ChartType.CLUSTERED_BAR];

      case ChartDatasets.HistoricalCosts:
        return [ChartType.CLUSTERED_BAR, ChartType.LINE];

      case ChartDatasets.AverageCostPerDevice:
        return [ChartType.BAR, ChartType.CLUSTERED_BAR, ChartType.LINE];

      default:
        return [ChartType.BAR, ChartType.CLUSTERED_BAR, ChartType.LINE];
    }
  }, [selectedDataset]);

  const handleDatasetChange = (event: SelectChangeEvent<ChartDatasets>) => {
    const newDataset = event.target.value as ChartDatasets;
    setSelectedDataset(newDataset);

    if (!allowedChartTypes.includes(selectedChartType)) {
      setSelectedChartType(allowedChartTypes[0]);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleChartTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedChartType(event.target.value as ChartType);
  };

  const handleGetStatementMonths = useCallback(async () => {
    try {
      const resp = await getStatementMonths();

      const statementMonths = resp.all_months
        .map((item) => item.statement_month)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      setStatementMonths(statementMonths);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const handleExportData = () => {
    window.dispatchEvent(new Event(ChartEvents.ExportData));
  };

  const handleExportAsImage = () => {
    window.dispatchEvent(new Event(ChartEvents.ExportImage));
  };

  const costFieldNames: string[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.TotalCost:
        return ["total_charges"];

      case ChartDatasets.EquipmentCost:
        return ["equipment_total"];

      case ChartDatasets.ServiceCost:
        return ["total_charges"];

      case ChartDatasets.InternationalCosts:
        return ["international_total"];

      case ChartDatasets.DataOverageCosts:
        return ["kb_charges"];

      case ChartDatasets.CostByCategory:
        return [
          "mrc",
          "equipment_total",
          "data_overage",
          "international_total",
          "total_other",
          "total_tax",
        ];

      case ChartDatasets.HistoricalCosts:
        return [
          "total_charges",
          "mrc",
          "equipment_total",
          "data_overage",
          "international_total",
          "total_other",
          "total_tax",
        ];

      case ChartDatasets.AverageCostPerDevice:
        return ["mrc"];

      default:
        return [];
    }
  }, [selectedDataset]);

  const costMinusFieldNames: string[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.ServiceCost:
        return ["equipment_total"];

      case ChartDatasets.DataOverageCosts:
        return ["intl_data_roam_charges"];

      default:
        return [];
    }
  }, [selectedDataset]);

  const byChartDataOptions: ByChartData[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.CostByCategory:
        return [ByChartData.COST_CATEGORIES];

      case ChartDatasets.CostByCostCenter:
        return [ByChartData.COST_BY_COST_CENTER];

      case ChartDatasets.HistoricalCosts:
        return [ByChartData.HISTORICAL_COSTS_CATEGORIES];

      case ChartDatasets.AverageCostPerDevice:
        return [ByChartData.CARRIER];

      default:
        return [ByChartData.CARRIER, ByChartData.DEVICE];
    }
  }, [selectedDataset]);

  const dataSourceOptions = useMemo(() => {
    if (selectedDataset === ChartDatasets.CostByCategory) {
      return getChartDataKeys(usageAndCostData, ByChartData.CARRIER);
    }

    return [];
  }, [usageAndCostData, selectedDataset]);

  useEffect(() => {
    handleGetStatementMonths();
  }, [handleGetStatementMonths]);

  // Fetch data when endDate or monthsToChart changes
  useEffect(() => {
    if (endDate && monthsToChart) {
      handleGetUsageAndCostData();
    }
  }, [
    endDate,
    monthsToChart,
    selectedDataset,
    selectedChartType,
    handleGetUsageAndCostData,
  ]);

  useEffect(() => {
    if (statementMonths.length && !endDate) {
      setEndDate(lastStatementMonth);
    }
  }, [statementMonths, lastStatementMonth, endDate]);

  useEffect(() => {
    updateQueryParams();
  }, [
    monthsToChart,
    endDate,
    selectedDataset,
    selectedChartType,
    updateQueryParams,
  ]);

  useEffect(() => {
    if (
      selectedDataset === ChartDatasets.CostByCategory &&
      monthsToChart > MAX_MONTHS_FOR_COST_BY_CATEGORY
    ) {
      setMonthsToChart(MAX_MONTHS_FOR_COST_BY_CATEGORY);
    }
  }, [selectedDataset, monthsToChart]);

  useEffect(() => {
    if (
      selectedDataset === ChartDatasets.CostByCategory &&
      selectedChartType !== ChartType.CLUSTERED_BAR
    ) {
      setSelectedChartType(ChartType.CLUSTERED_BAR);
    }
  }, [selectedDataset, selectedChartType]);

  return (
    <div className="flex flex-col flex-1">
      <div className="grid md:grid-cols-2 lg:grid-cols-3 border border-l-0 border-r-0 border-primary-800 border-solid px-4 items-center gap-2">
        {/* Left column - inputs */}
        <div className="flex flex-row items-start pt-2 pb-2 gap-4">
          <div>
            <TextField
              label="End Billing Date"
              type="month"
              value={endDate}
              onChange={handleEndDateChange}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                htmlInput: {
                  min: firstStatementMonth,
                  max: lastStatementMonth,
                },
              }}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </div>

          <div>
            <TextField
              data-testid="months-to-chart"
              label="Months"
              type="number"
              value={monthsToChart}
              onChange={(e) =>
                setMonthsToChart(
                  Math.min(
                    monthsRange.max,
                    Math.max(monthsRange.min, parseInt(e.target.value) || 1)
                  )
                )
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                htmlInput: {
                  min: monthsRange.min,
                  max: monthsRange.max,
                },
              }}
              margin="normal"
              variant="outlined"
              fullWidth
              sx={{
                minWidth: 80,
              }}
            />
          </div>

          <div>
            <TextField
              select
              label="Chart Type"
              value={selectedChartType}
              onChange={handleChartTypeChange}
              margin="normal"
              variant="outlined"
              fullWidth
              slotProps={{
                select: {
                  sx: {
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                    },
                  },
                },
              }}
            >
              {allowedChartTypes.includes(ChartType.BAR) && (
                <MenuItem value={ChartType.BAR}>
                  <ListItemIcon className="mr-2">
                    <IoBarChart size={20} />
                  </ListItemIcon>
                  <span>Bar</span>
                </MenuItem>
              )}

              {allowedChartTypes.includes(ChartType.CLUSTERED_BAR) && (
                <MenuItem value={ChartType.CLUSTERED_BAR}>
                  <ListItemIcon className="mr-2">
                    <FaChartBar size={20} />
                  </ListItemIcon>
                  <span>Clustered</span>
                </MenuItem>
              )}

              {allowedChartTypes.includes(ChartType.LINE) && (
                <MenuItem value={ChartType.LINE}>
                  <ListItemIcon className="mr-2">
                    <FaChartLine size={20} />
                  </ListItemIcon>
                  <span>Line</span>
                </MenuItem>
              )}
            </TextField>
          </div>

          <div className="md:block lg:hidden">
            <Select
              data-testid="dataset-select"
              value={selectedDataset}
              onChange={handleDatasetChange}
              variant="outlined"
              notched={false}
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 1,
                },
                marginTop: "16px",
                marginBottom: "8px",
              }}
            >
              {Object.values(ChartDatasets).map((dataset) => (
                <MenuItem key={dataset} value={dataset}>
                  {dataset}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        {/* Center column - Dataset selector */}
        <div className="hidden lg:flex justify-center items-start pt-2 pb-2 mt-2">
          <Select
            data-testid="dataset-select"
            value={selectedDataset}
            onChange={handleDatasetChange}
            variant="outlined"
            notched={false}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 1,
              },
            }}
          >
            {Object.values(ChartDatasets).map((dataset) => (
              <MenuItem key={dataset} value={dataset}>
                {dataset}
              </MenuItem>
            ))}
          </Select>
        </div>

        {/* Right column - buttons */}
        <div className="flex flex-row gap-2 justify-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExportData}
          >
            Export Data
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportAsImage}
          >
            Export as Image
          </Button>
        </div>
      </div>

      {[
        ChartDatasets.TotalCost,
        ChartDatasets.EquipmentCost,
        ChartDatasets.ServiceCost,
        ChartDatasets.InternationalCosts,
        ChartDatasets.DataOverageCosts,
        ChartDatasets.CostByCategory,
        ChartDatasets.HistoricalCosts,
        ChartDatasets.AverageCostPerDevice,
      ].includes(selectedDataset) && (
        <DataAndUsageCostsChart
          startDate={moment(endDate)
            .subtract(monthsToChart - 1, "months")
            .format("YYYY-MM")}
          endDate={endDate}
          selectedChartType={selectedChartType}
          usageAndCostData={usageAndCostData}
          isLoading={loadingData}
          fieldNames={costFieldNames}
          minusFieldNames={costMinusFieldNames}
          byChartDataOptions={byChartDataOptions}
          dataSet={selectedDataset}
          dataSourceOptions={dataSourceOptions}
          isMenuCollapsed={isMenuCollapsed}
          onMenuCollapsedChange={setIsMenuCollapsed}
        />
      )}

      {selectedDataset === ChartDatasets.CostByCostCenter && (
        <CostByCostCenterBarChart
          startDate={moment(endDate)
            .subtract(monthsToChart - 1, "months")
            .format("YYYY-MM")}
          endDate={endDate}
          chartType={selectedChartType}
          isMenuCollapsed={isMenuCollapsed}
          onMenuCollapsedChange={setIsMenuCollapsed}
        />
      )}
    </div>
  );
}
