import {
  Box,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const StatsCardSkeleton = () => (
  <Card sx={{ p: 3, mb: 3 }}>
    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
      {/* Controls Section */}
      <Box sx={{ display: "flex", gap: 2 }}>
        {[...Array(3)].map((_, i) => (
          <Skeleton key={i} width={180} height={40} />
        ))}
      </Box>

      {/* Stats Section */}
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexGrow: 1,
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        {/* Regular stats */}
        {[...Array(3)].map((_, i) => (
          <Box key={i}>
            <Skeleton width={100} height={20} sx={{ mb: 1 }} />
            <Skeleton width={80} height={32} />
          </Box>
        ))}

        {/* Total Savings box */}
        <Box
          sx={{
            backgroundColor: "primary.main",
            p: 2,
            pt: 1,
            mt: -1,
            borderRadius: 2,
            minWidth: 200,
          }}
        >
          <Skeleton width={120} height={20} sx={{ mb: 1 }} />
          <Skeleton width={160} height={40} />
        </Box>
      </Box>
    </Box>
  </Card>
);

export const ChartSkeleton = () => (
  <>
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
      <Skeleton width={150} height={32} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={400} />
  </>
);

export const MetricsTableSkeleton = () => (
  <Card sx={{ p: 3, mt: 3 }}>
    <Skeleton width={200} height={32} sx={{ mb: 2 }} />
    <Table size="small">
      <TableHead>
        <TableRow>
          {[...Array(6)].map((_, i) => (
            <TableCell key={i}>
              <Skeleton width={80} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(3)].map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {[...Array(6)].map((_, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton width={80} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Card>
);
