import { LineChart } from "@mui/x-charts";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../../api";

import {
  ByChartData,
  chartColors,
  ChartDatasets,
  chartMargins,
  ChartType,
  DataKeyToNameMap,
  lineChartSlotProps,
} from "../../constants";
import { ChartEvents } from "../../events";
import { currencyFormatter } from "../../utils";
import { captureChart } from "../utils";

interface HistoricalCostDataItem {
  month: string;
  total_charges: number;
  mrc: number;
  equipment_total: number;
  data_overage: number;
  international_total: number;
  total_other: number;
  total_tax: number;
}

export default function HistoricalCostsChart({
  data,
  startDate,
  endDate,
  selectedDataKeys,
  chartType,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  selectedDataKeys: string[];
  chartType: ChartType;
  dataSet: ChartDatasets;
}) {
  const processedData = useMemo(() => {
    const filteredData = data.filter((item) =>
      moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
    );

    const monthlyTotals: { [key: string]: HistoricalCostDataItem } = {};

    filteredData.forEach((item) => {
      const month = moment(item.invoice_date).format("MMM YY");

      if (!monthlyTotals[month]) {
        monthlyTotals[month] = {
          month,
          total_charges: 0,
          mrc: 0,
          equipment_total: 0,
          data_overage: 0,
          international_total: 0,
          total_other: 0,
          total_tax: 0,
        };
      }

      monthlyTotals[month].total_charges += Number(item.total_charges) || 0;
      monthlyTotals[month].mrc += Number(item.mrc) || 0;
      monthlyTotals[month].equipment_total += Number(item.equipment_total) || 0;
      monthlyTotals[month].data_overage +=
        Number(item.kb_charges) - Number(item.intl_data_roam_charges) || 0;
      monthlyTotals[month].international_total +=
        Number(item.international_total) || 0;
      monthlyTotals[month].total_other += Number(item.total_other) || 0;
      monthlyTotals[month].total_tax += Number(item.total_tax) || 0;
    });

    return Object.values(monthlyTotals).sort((a, b) =>
      moment(a.month, "MMM YY").diff(moment(b.month, "MMM YY"))
    );
  }, [data, startDate, endDate]);

  const displayedData = useMemo(() => {
    return processedData.map((item) => {
      const displayedItem: { [key: string]: string | number } = {
        month: item.month,
      };
      selectedDataKeys.forEach((key) => {
        displayedItem[key] = item[key as keyof HistoricalCostDataItem] || 0;
      });
      return displayedItem;
    });
  }, [processedData, selectedDataKeys]);

  const handleExportData = useCallback(() => {
    if (displayedData.length === 0) return;

    const header = ["month", ...selectedDataKeys].join(",");
    const rows = displayedData.map((item) => {
      return [
        item.month,
        ...selectedDataKeys.map((key) =>
          currencyFormatter(item[key] || 0, false)
        ),
      ].join(",");
    });

    const csvContent = [header, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "historical-costs.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [displayedData, selectedDataKeys]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet,
      chartType,
      byChartData: ByChartData.COST_CATEGORIES,
      startDate,
      endDate,
    });
  }, [dataSet, chartType, startDate, endDate]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);
    window.addEventListener(ChartEvents.ExportImage, handleCaptureChart);

    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
      window.removeEventListener(ChartEvents.ExportImage, handleCaptureChart);
    };
  }, [handleExportData, handleCaptureChart]);

  return (
    <LineChart
      dataset={displayedData}
      series={selectedDataKeys.map((key, index) => ({
        dataKey: key,
        label: DataKeyToNameMap[key] || key,
        valueFormatter: (value) => currencyFormatter(value),
        color: chartColors[index % chartColors.length],
      }))}
      xAxis={[
        {
          scaleType: "point",
          dataKey: "month",
        },
      ]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      height={window.innerHeight - 300}
      slotProps={lineChartSlotProps}
      margin={chartMargins}
    />
  );
}
