import {
  BarChartSlotProps,
  CardinalDirections,
  LineChartSlotProps,
} from "@mui/x-charts";

export enum ChartType {
  BAR = "bar",
  LINE = "line",
  CLUSTERED_BAR = "clustered-bar",
}

export enum ByChartData {
  CARRIER = "carrier_name",
  DEVICE = "formatted_device_type",
  COST_CATEGORIES = "cost_categories",
  COST_BY_COST_CENTER = "cost_by_cost_center",
  HISTORICAL_COSTS_CATEGORIES = "historical_costs_categories",
}

export const ByChartDataMap: {
  [key: string]: string;
} = {
  [ByChartData.CARRIER]: "Carrier",
  [ByChartData.DEVICE]: "Device",
  [ByChartData.COST_CATEGORIES]: "Cost Category",
  [ByChartData.COST_BY_COST_CENTER]: "Cost Center",
  [ByChartData.HISTORICAL_COSTS_CATEGORIES]: "Cost Categories (Historical)",
};

export enum ChartDatasets {
  TotalCost = "Total Cost",
  EquipmentCost = "Equipment Cost",
  ServiceCost = "Service Cost",
  InternationalCosts = "International Costs",
  DataOverageCosts = "Data Overage Costs",
  CostByCategory = "Cost By Category",
  CostByCostCenter = "Cost By Cost Center",
  HistoricalCosts = "Historical Costs",
  AverageCostPerDevice = "Average Cost per Device",
}

export const CostCategoryMap: {
  [key: string]: string;
} = {
  mrc: "MRC $",
  equipment_total: "Equip $",
  data_overage: "Overage $",
  international_total: "Intl $",
  total_other: "Other $",
  total_tax: "Tax $",
};

export const HistoricalCostsCategoriesMap: {
  [key: string]: string;
} = {
  ...CostCategoryMap,
  total_charges: "Total Charges",
};

export const DataKeyToNameMap: {
  [key: string]: string;
} = {
  mrc: "MRC",
  total_charges: "Total Charges",
  equipment_total: "Equipment",
  international_total: "International",
  intl_data_roam_charges: "International Data Roam Charges",
  kb_charges: "KB Charges",
  data_overage: "Data Overage",
  total_other: "Other",
  total_tax: "Tax",
};

export const barChartSlotProps: BarChartSlotProps = {
  legend: {
    position: {
      horizontal: "middle",
      vertical: "top",
    },
  },
};

export const lineChartSlotProps: LineChartSlotProps = {
  legend: {
    direction: "row",
    position: { vertical: "top", horizontal: "middle" },
    padding: 20,
  },
};

export const chartMargins: Partial<CardinalDirections<number>> = {
  top: 80,
  right: 20,
  bottom: 20,
  left: 80,
};

export const chartColors: string[] = [
  "#5C88C5",
  "#F5796D",
  "#64C195",
  "#FCC777",
  "#BD7CB4",
  "#F58D74",
  "#ADD68A",
  "#EBE87B",
  "#6578D9",
  "#F5A872",
  "#54C5C3",
  "#7E6AAF",
];

export const carrierColors: { [key: string]: string } = {
  ATT: "#39a0db",
  FirstNet: "#54c5c3",
  Verizon: "#c41222",
  "T-Mobile": "#eb7bb4",
  "US Cellular": "#0f3570",
  Rogers: "#0f3570",
  Telus: "#7e6aaf",
  Bell: "#6578d9",
  "Carrier 9": "#6578d9",
  "Carrier 10": "#add68a",
};

export const MAX_MONTHS_FOR_COST_BY_CATEGORY = 12;
