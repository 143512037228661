import { BarChart, LineChart } from "@mui/x-charts";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCostItem } from "../../api";
import {
  barChartSlotProps,
  ByChartData,
  carrierColors,
  chartColors,
  ChartDatasets,
  chartMargins,
  ChartType,
  lineChartSlotProps,
} from "../../constants";
import { ChartEvents } from "../../events";
import { currencyFormatter } from "../../utils";
import { captureChart } from "../utils";

interface AverageCostDataItem {
  month: string;
  [carrier: string]: string | number;
}

export default function AverageCostPerDeviceChart({
  data,
  startDate,
  endDate,
  selectedDataKeys,
  chartType,
  fieldNames,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  selectedDataKeys: string[];
  chartType: ChartType;
  fieldNames: string[];
  dataSet: ChartDatasets;
}) {
  const processedData: AverageCostDataItem[] = useMemo(() => {
    const filteredData = data.filter((item) =>
      moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
    );

    const monthlyCarrierData: {
      [month: string]: {
        [carrier: string]: { totalMrc: number; deviceCount: number };
      };
    } = {};

    // Aggregate MRC and device count by month and carrier
    filteredData.forEach((item) => {
      const month = moment(item.invoice_date).format("MMM YYYY");
      const carrier = item.carrier_name;

      if (!monthlyCarrierData[month]) {
        monthlyCarrierData[month] = {};
      }
      if (!monthlyCarrierData[month][carrier]) {
        monthlyCarrierData[month][carrier] = { totalMrc: 0, deviceCount: 0 };
      }

      monthlyCarrierData[month][carrier].totalMrc += Number(item.mrc);
      monthlyCarrierData[month][carrier].deviceCount += Number(
        item.device_count
      );
    });

    // Calculate averages
    return Object.entries(monthlyCarrierData)
      .map(([month, carrierData]) => {
        const result: AverageCostDataItem = { month };

        Object.entries(carrierData).forEach(([carrier, data]) => {
          result[carrier] =
            data.deviceCount > 0 ? data.totalMrc / data.deviceCount : 0;
        });

        return result;
      })
      .sort((a, b) =>
        moment(a.month, "MMM YYYY").diff(moment(b.month, "MMM YYYY"))
      );
  }, [data, startDate, endDate]);

  // Format data for display
  const formattedData = useMemo(() => {
    return processedData.map((item) => ({
      ...item,
      month: moment(item.month, "MMM YYYY").format("MMM YY"),
    }));
  }, [processedData]);

  const handleExportData = useCallback(() => {
    if (formattedData.length === 0) return;

    // Get all keys except 'month'
    const keys = Object.keys(formattedData[0]).filter((key) => key !== "month");

    // Create CSV header
    const header = ["month", ...keys].join(",");

    // Create CSV rows
    const rows = formattedData.map((item) => {
      return [
        item.month,
        ...keys.map((key) =>
          currencyFormatter(item[key as keyof typeof item] || 0, false)
        ),
      ].join(",");
    });

    // Combine header and rows
    const csvContent = [header, ...rows].join("\n");

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "average-cost-per-device.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [formattedData]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet,
      chartType,
      byChartData: ByChartData.CARRIER,
      startDate,
      endDate,
    });
  }, [dataSet, chartType, startDate, endDate]);

  useEffect(() => {
    const exportImageHandler = () => handleCaptureChart();
    window.addEventListener(ChartEvents.ExportImage, exportImageHandler);
    return () => {
      window.removeEventListener(ChartEvents.ExportImage, exportImageHandler);
    };
  }, [handleCaptureChart]);

  useEffect(() => {
    const exportDataHandler = () => handleExportData();
    window.addEventListener(ChartEvents.ExportData, exportDataHandler);
    return () => {
      window.removeEventListener(ChartEvents.ExportData, exportDataHandler);
    };
  }, [handleExportData]);

  const chartSeries = selectedDataKeys.map((key) => ({
    dataKey: key,
    label: key,
    stack: chartType === ChartType.BAR ? "carrier" : undefined,
    valueFormatter: (value: number | null) =>
      value ? currencyFormatter(value) : "$0.00",
    color:
      carrierColors[key] ||
      chartColors[selectedDataKeys.indexOf(key) % chartColors.length],
  }));

  const commonProps = {
    dataset: formattedData,
    series: chartSeries,
    xAxis: [
      {
        scaleType:
          chartType === ChartType.LINE ? ("point" as const) : ("band" as const),
        dataKey: "month",
      },
    ],
    yAxis: [
      {
        scaleType: "linear" as const,
        valueFormatter: (value: number) => currencyFormatter(value),
      },
    ],
    margin: chartMargins,
  };

  return chartType === ChartType.LINE ? (
    <LineChart {...commonProps} slotProps={lineChartSlotProps} />
  ) : (
    <BarChart {...commonProps} slotProps={barChartSlotProps} />
  );
}
