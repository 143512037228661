import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SavingsAnalysisItem } from "./types";
import { formatCurrency } from "./utils";

export default function CostDeviceMetricsTable({
  savingsAnalysisData,
}: {
  savingsAnalysisData: SavingsAnalysisItem[];
}) {
  // Get baseline (first month) and current (latest month) data
  const baselineData = savingsAnalysisData[0];
  const currentData = savingsAnalysisData[savingsAnalysisData.length - 1];

  const metricsData = [
    {
      name: "Smart Devices",
      baselineCostPerDev: calculateCostPerDevice(
        baselineData.smart_device_count,
        baselineData.total_smart_charges
      ),
      baselineCount: baselineData.smart_device_count,
      currentCostPerDev: calculateCostPerDevice(
        currentData.smart_device_count,
        currentData.total_smart_charges
      ),
      currentCount: currentData.smart_device_count,
    },
    {
      name: "Data Devices",
      baselineCostPerDev: calculateCostPerDevice(
        baselineData.data_device_count + baselineData.tablet_device_count,
        baselineData.total_data_charges + baselineData.total_tablet_charges
      ),
      baselineCount:
        baselineData.data_device_count + baselineData.tablet_device_count,
      currentCostPerDev: calculateCostPerDevice(
        currentData.data_device_count + currentData.tablet_device_count,
        currentData.total_data_charges + currentData.total_tablet_charges
      ),
      currentCount:
        currentData.data_device_count + currentData.tablet_device_count,
    },
    {
      name: "Phone Devices",
      baselineCostPerDev: calculateCostPerDevice(
        baselineData.phone_device_count,
        baselineData.total_phone_charges
      ),
      baselineCount: baselineData.phone_device_count,
      currentCostPerDev: calculateCostPerDevice(
        currentData.phone_device_count,
        currentData.total_phone_charges
      ),
      currentCount: currentData.phone_device_count,
    },
  ];

  function calculateCostPerDevice(count: number, totalCost: number): number {
    return count > 0 ? totalCost / count : 0;
  }

  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Cost/Device Metrics
      </Typography>
      <Box sx={{ overflowX: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Baseline</TableCell>
              <TableCell align="right">Cost/Dev</TableCell>
              <TableCell align="right">Count</TableCell>
              <TableCell>Current</TableCell>
              <TableCell align="right">Cost/Dev</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metricsData.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  {formatCurrency(row.baselineCostPerDev)}
                </TableCell>
                <TableCell align="right">{row.baselineCount}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  {formatCurrency(row.currentCostPerDev)}
                </TableCell>
                <TableCell align="right">{row.currentCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
}
