import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { PageContainer } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { chartColors } from "../../Constants/chart-colors";
import { getSavingsAnalysis } from "./api";
import CostDeviceMetricsTable from "./CostDeviceMetricsTable";
import { datasetOptions } from "./data";
import {
  ChartSkeleton,
  MetricsTableSkeleton,
  StatsCardSkeleton,
} from "./Skeletons";
import { SavingsAnalysisItem } from "./types";
import { formatCurrency } from "./utils";

interface MetricsRow {
  name: string;
  baselineCostPerDev: number;
  baselineCount: number;
  currentCostPerDev: number;
  currentCount: number;
}

// Add this custom tooltip component before the main component
const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (!active || !payload) return null;

  return (
    <Card sx={{ p: 2, boxShadow: 2, bgcolor: "background.paper" }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        {moment(label).format("MMM YYYY")}
      </Typography>
      {payload.map((entry) => (
        <Box
          key={entry.name || ""}
          sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              bgcolor: entry.color,
              borderRadius: "50%",
            }}
          />
          <Typography variant="body2">
            {entry.name || ""}:{" "}
            {typeof entry.value === "number" && !Number.isInteger(entry.value)
              ? `$${entry.value.toFixed(2)}`
              : entry.value}
          </Typography>
        </Box>
      ))}
    </Card>
  );
};

export default function CumulativeSavingsDashboard() {
  const [savingsAnalysisData, setSavingsAnalysisData] = useState<
    SavingsAnalysisItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedDataset, setSelectedDataset] = useState(
    datasetOptions[0].BarDisplay
  );
  const [selectedBase, setSelectedBase] = useState(
    datasetOptions[0].LineOptions[0].Column
  );
  const [selectedEndMonth, setSelectedEndMonth] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSavingsAnalysis();
        setSavingsAnalysisData(data);
        if (data.length > 0) {
          const months = data
            .map((d) => d.display_statement_month)
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
          setSelectedEndMonth(months[0]);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Replace the loading check with this
  if (isLoading || savingsAnalysisData.length === 0) {
    return (
      <PageContainer>
        <StatsCardSkeleton />
        <ChartSkeleton />
        <MetricsTableSkeleton />
      </PageContainer>
    );
  }

  // Get available months for selection
  const availableMonths: string[] = savingsAnalysisData
    .map((d) => d.display_statement_month)
    .sort((a, b) => b.localeCompare(a)); // Sort descending

  // Filter data up to selected end month
  const filteredData = savingsAnalysisData.filter(
    (d) => d.display_statement_month <= selectedEndMonth
  );

  // Get the latest data point from filtered data
  const latestData = filteredData[filteredData.length - 1];

  // Get current dataset configuration
  const currentDataset = datasetOptions.find(
    (d) => d.BarDisplay === selectedDataset
  );

  // Add these formatting helpers after the existing formatCurrency function
  const formatDate = (date: string) => moment(date).format("MMM YYYY");

  if (isLoading) {
    return (
      <PageContainer>
        <StatsCardSkeleton />
        <ChartSkeleton />
        <MetricsTableSkeleton />
      </PageContainer>
    );
  }

  // Add this export function before the return statement
  const handleExportData = () => {
    // Get current dataset and line option labels
    const barDisplay = currentDataset?.BarDisplay;
    const lineDisplay = currentDataset?.LineOptions.find(
      (opt) => opt.Column === selectedBase
    )?.Display;

    // Determine columns to export based on dataset configuration
    const barColumns = currentDataset?.CheckColumns?.length
      ? currentDataset.CheckColumns.filter((col) => col.include).map((col) => ({
          column: col.Column,
          display: col.Display,
        }))
      : currentDataset?.BarColumns.map((column) => ({
          column: column,
          display: barDisplay,
        }));

    // Build headers
    const headers = [
      "Month",
      ...(barColumns?.map((col) => col.display) || []),
      lineDisplay,
    ];

    // Build data rows
    const csvData = filteredData.map((row) => [
      formatDate(row.display_statement_month),
      ...(barColumns?.map(
        (col) => row[col.column as keyof SavingsAnalysisItem]
      ) || []),
      row[selectedBase as keyof SavingsAnalysisItem],
    ]);

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${barDisplay}-${lineDisplay}-${formatDate(selectedEndMonth)}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageContainer>
      <Card sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {/* Controls Section - Now First */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl size="small" sx={{ minWidth: 180 }}>
              <InputLabel>End Month</InputLabel>
              <Select
                value={selectedEndMonth}
                onChange={(e) => setSelectedEndMonth(e.target.value)}
                label="End Month"
              >
                {availableMonths.map((month) => (
                  <MenuItem key={month} value={month}>
                    {moment(month).format("MMMM YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 180 }}>
              <InputLabel>Dataset</InputLabel>
              <Select
                value={selectedDataset}
                onChange={(e) => {
                  setSelectedDataset(e.target.value);
                  // Reset base selection to first available option for new dataset
                  const newDataset = datasetOptions.find(
                    (d) => d.BarDisplay === e.target.value
                  );
                  if (newDataset) {
                    setSelectedBase(newDataset.LineOptions[0].Column);
                  }
                }}
                label="Dataset"
              >
                {datasetOptions.map((dataset) => (
                  <MenuItem key={dataset.BarDisplay} value={dataset.BarDisplay}>
                    {dataset.BarDisplay}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 180 }}>
              <InputLabel>Device Type</InputLabel>
              <Select
                value={selectedBase}
                onChange={(e) => setSelectedBase(e.target.value)}
                label="Device Type"
              >
                {currentDataset?.LineOptions.map((option) => (
                  <MenuItem key={option.Column} value={option.Column}>
                    {option.Display === "Devices"
                      ? "All Devices"
                      : option.Display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Stats Section */}
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography variant="body2" color="textSecondary">
                Zero Use Savings
              </Typography>
              <Typography variant="h6">
                {formatCurrency(latestData.monthly_zero_use_savings)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                Total Devices
              </Typography>
              <Typography variant="h6">{latestData.device_count}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                Period
              </Typography>
              <Typography variant="h6">{filteredData.length} months</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "primary.main",
                p: 2,
                pt: 1, // Reduced top padding
                mt: -1, // Added negative margin to align with other boxes
                borderRadius: 2,
                color: "white",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                Total Savings
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {formatCurrency(latestData.cumulative_savings)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={handleExportData}
          size="small"
        >
          Export Chart Data
        </Button>
      </Box>

      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={filteredData}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <XAxis
            dataKey="display_statement_month"
            tickFormatter={formatDate}
            height={60}
            angle={-45}
            textAnchor="end"
            interval={0}
            dy={20}
          />
          <YAxis yAxisId="savings" orientation="left" />
          <YAxis yAxisId="base" orientation="right" />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "rgba(0, 0, 0, 0.05)" }}
          />

          {/* Render CheckColumns bars if they exist */}
          {currentDataset?.CheckColumns?.map(
            (col, index) =>
              col.include && (
                <Bar
                  key={col.Column}
                  yAxisId="savings"
                  dataKey={col.Column}
                  name={col.Display}
                  stackId="savings"
                  fill={chartColors[index % chartColors.length]}
                />
              )
          )}

          {/* Render BarColumns if no CheckColumns exist */}
          {(!currentDataset?.CheckColumns ||
            currentDataset.CheckColumns.length === 0) &&
            currentDataset?.BarColumns.map((column, index) => (
              <Bar
                key={column}
                yAxisId="savings"
                dataKey={column}
                name={currentDataset.BarDisplay}
                fill={chartColors[index % chartColors.length]}
              />
            ))}

          <Line
            yAxisId="base"
            type="monotone"
            dataKey={selectedBase}
            name={
              currentDataset?.LineOptions.find(
                (opt) => opt.Column === selectedBase
              )?.Display
            }
            stroke={chartColors[chartColors.length - 1]}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <CostDeviceMetricsTable savingsAnalysisData={savingsAnalysisData} />
    </PageContainer>
  );
}
