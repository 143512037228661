import { DatasetOption } from "./types";

// Replace the existing dataset and base options with the new structure
export const datasetOptions: DatasetOption[] = [
  {
    BarDisplay: "Cumulative Savings",
    BarColumns: ["cumulative_savings"],
    CheckColumns: [
      {
        Display: "Optimization",
        Column: "cumulative_savings_without_zero_use",
        include: true,
      },
      {
        Display: "Zero Use",
        Column: "cumulative_zero_use_savings",
        include: true,
      },
    ],
    LineOptions: [
      { Display: "Devices", Column: "device_count" },
      { Display: "Data Devices", Column: "data_device_count" },
      { Display: "Phone Devices", Column: "phone_device_count" },
    ],
  },
  {
    BarDisplay: "Monthly Savings",
    BarColumns: ["adjusted_savings"],
    CheckColumns: [
      {
        Display: "Optimization",
        Column: "adjusted_savings_without_zero_use",
        include: true,
      },
      {
        Display: "Zero Use",
        Column: "monthly_zero_use_savings",
        include: true,
      },
    ],
    LineOptions: [
      { Display: "Devices", Column: "device_count" },
      { Display: "Cost/Device", Column: "avg_invoice" },
    ],
  },
  {
    BarDisplay: "GB Usage/month",
    BarColumns: ["gb_usage"],
    LineOptions: [
      { Display: "Cost/GB", Column: "avg_data_cost_per_gb" },
      { Display: "Devices", Column: "device_count" },
    ],
  },
  {
    BarDisplay: "Voice Mins/month",
    BarColumns: ["total_mins_of_use"],
    LineOptions: [
      { Display: "Devices", Column: "device_count" },
      { Display: "Phone Devices", Column: "phone_device_count" },
    ],
  },
  {
    BarDisplay: "Monthly Invoices",
    BarColumns: ["monthly_invoice"],
    LineOptions: [
      { Display: "Devices", Column: "device_count" },
      { Display: "Cost/Device", Column: "avg_invoice" },
    ],
  },
];

export const columnMappings = {
  "Cumulative Savings": {
    schemaDataset: "cumulative_savings",
    barColumns: ["cumulative_savings"],
    checkColumns: {
      Optimization: "cumulative_savings_without_zero_use",
      "Zero Use": "cumulative_zero_use_savings",
    },
    lineOptions: {
      Devices: "device_count",
      "Data Devices": "data_device_count",
      "Phone Devices": "phone_device_count",
    },
  },
  "Monthly Savings": {
    schemaDataset: "adjusted_savings",
    barColumns: ["adjusted_savings"],
    checkColumns: {
      Optimization: "adjusted_savings_without_zero_use",
      "Zero Use": "monthly_zero_use_savings",
    },
    lineOptions: {
      Devices: "device_count",
      "Cost/Device": "avg_invoice",
    },
  },
  "GB Usage/month": {
    schemaDataset: "gb_usage",
    barColumns: ["gb_usage"],
    lineOptions: {
      "Cost/GB": "avg_data_cost_per_gb",
      Devices: "device_count",
    },
  },
  "Voice Mins/month": {
    schemaDataset: "total_mins_of_use",
    barColumns: ["total_mins_of_use"],
    lineOptions: {
      Devices: "device_count",
      "Phone Devices": "phone_device_count",
    },
  },
  "Monthly Invoices": {
    schemaDataset: "adjusted_invoice",
    barColumns: ["adjusted_invoice"],
    lineOptions: {
      Devices: "device_count",
      "Cost/Device": "avg_invoice",
    },
  },
};
