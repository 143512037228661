import { useMemo } from "react";
import { UsageAndCostItem } from "../../api";

import { ChartDatasets, ChartType } from "../../constants";
import HistoricalCostsClusteredBarChart from "./HistoricalCostsClusteredBarChart";
import HistoricalCostsLineChart from "./HistoricalCostsLineChart";
import { HistoricalCostDataItem, processHistoricalData } from "./utils";

export default function HistoricalCostsChart({
  data,
  startDate,
  endDate,
  selectedDataKeys,
  chartType,
  dataSet,
}: {
  data: UsageAndCostItem[];
  startDate: string;
  endDate: string;
  selectedDataKeys: string[];
  chartType: ChartType;
  dataSet: ChartDatasets;
}) {
  const processedData = useMemo(
    () => processHistoricalData(data, startDate, endDate),
    [data, startDate, endDate]
  );

  const displayedData = useMemo(() => {
    return processedData.map((item) => {
      const displayedItem: { [key: string]: string | number } = {
        month: item.month,
      };
      selectedDataKeys.forEach((key) => {
        displayedItem[key] = item[key as keyof HistoricalCostDataItem] || 0;
      });
      return displayedItem;
    });
  }, [processedData, selectedDataKeys]);

  return chartType === ChartType.CLUSTERED_BAR ? (
    <HistoricalCostsClusteredBarChart
      data={data}
      startDate={startDate}
      endDate={endDate}
      selectedDataKeys={selectedDataKeys}
      chartType={chartType}
      dataSet={dataSet}
    />
  ) : (
    <HistoricalCostsLineChart
      data={data}
      startDate={startDate}
      endDate={endDate}
      selectedDataKeys={selectedDataKeys}
      chartType={chartType}
      dataSet={dataSet}
    />
  );
}
